import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "../../UI/Button/Button";

import spbw from "../../../utils/spbw";

import biggerImg from "../../../assets/img/minimap-bigger.png";
import smallerImg from "../../../assets/img/minimap-smaller.png";
import collapseImg from "../../../assets/img/toggle-minimap.png";
import mapBtn from "../../../assets/img/map-btn.png";
import closeIcon from "../../../assets/img/quit.png";

import gameConfig from "../../../config/game.json";
import cls from "./minimap.module.css";

const mmPatterns = gameConfig.minimapPatterns;

function Minimap({ children, guessDisabled, onGuess }) {
  const btnRowRef = useRef();
  const [mmCollapsed, setMmCollapsed] = useState(false);
  const [mmPattern, setMmPattern] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      {isVisible ? (
        <div
          className={cls.minimap}
          //   style={{
          //     "--mm-wid": mmPatterns[mmPattern].w,
          //     "--mm-hgt": mmCollapsed
          //       ? window.getComputedStyle(btnRowRef.current).height
          //       : mmPatterns[mmPattern].h,
          //   }}
        >
          {/* <div className={cls.btn_row} ref={btnRowRef}>
        <div>
          <button
            className={spbw("btn-game", cls.tool_btn)}
            onClick={() =>
              setMmPattern(Math.min(mmPattern + 1, mmPatterns.length - 1))
            }
            title="Bigger"
          >
            <img src={biggerImg} alt="+" />
          </button>
          <button
            className={spbw("btn-game", cls.tool_btn)}
            onClick={() => setMmPattern(Math.max(mmPattern - 1, 0))}
            title="Smaller"
          >
            <img src={smallerImg} alt="-" />
          </button>
        </div>
      </div> */}
          <div className={cls.map}>{children}</div>
          <div>
            {guessDisabled ? (
              <Button
                className={spbw("btn-block ", cls.minimap_btn)}
                disabled={guessDisabled}
              >
                Cliquer sur la carte pour choisir une destination
              </Button>
            ) : (
              <Button
                className={spbw("btn-block ", cls.minimap_btn)}
                onClick={onGuess}
              >
                Valider
              </Button>
            )}
          </div>
          <div className="title-center">
            <Button
              className={cls.close_btn}
              onClick={() => {
                setIsVisible(false);
              }}
            >
              <img
                className={cls.close_icon}
                src={closeIcon}
                alt="close icon"
              />{" "}
              Fermer la carte
            </Button>
          </div>
        </div>
      ) : (
        <>
          <img
            className={cls.map_btn}
            src={mapBtn}
            onClick={() => {
              setIsVisible(true);
            }}
          ></img>
        </>
      )}
    </>
  );
}
Minimap.propTypes = {
  guessDisabled: PropTypes.bool,
  onGuess: PropTypes.func,
};
Minimap.defaultProps = {
  guessDisabled: true,
  onGuess: () => {},
};

export default Minimap;
