import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import EnterGame from "../../components/EnterGame/EnterGame";

import cls from "./home.module.css";
import TextDetails from "../../components/TextDetails/TextDetails";
import mobileLandscapeIcon from "../../assets/img/home-icons/mobile-landscape-icon.png";

function Home() {
  const [explanationViewed, setExplanationViewed] = useState(false);

  useEffect(() => {
    var sessionExplanationViewed = sessionStorage.getItem("explanationViewed");
    if (sessionExplanationViewed == null) {
      setExplanationViewed(false);
    } else {
      setExplanationViewed(true);
    }
  }, []);
  return (
    <div>
      <Header label="Stats" href="/stats" />
      <main className="header-above">
        <div className="container main-container">
          <section className="section">
            <h2 className="title section-title title-center">
              Explication du jeu
            </h2>
            {explanationViewed ? (
              <p
                onClick={() => {
                  setExplanationViewed(false);
                }}
              >
                Revoir les explications
              </p>
            ) : (
              <>
                <p className="paragraph section-paragraph paragraph-center">
                  Bienvenue sur ce jeu inspiré de Geoguessr.
                </p>
                <TextDetails
                  title={"Qu'est-ce que c'est ?"}
                  description={
                    "GeoGuessr est un jeu de géographie dans lequel vous êtes déposé quelque part dans le monde dans un panorama de rue, et votre mission est de trouver des indices qui vous permettront de deviner votre position sur la carte du monde."
                  }
                ></TextDetails>
                <TextDetails
                  title="Objectif"
                  description="L'objectif est de déterminer, le plus précisément possible, l'endroit qui vous est montré à l'écran en plaçant un point sur une carte Google Maps."
                ></TextDetails>
                <TextDetails
                  title="Comment faire ?"
                  description="Déplacez-vous en cliquant sur les flèches ou sur l'endroit où vous souhaitez vous rendre pour observer. regardez autour de vous en glissant votre doigt. Pour valider une destination, cliquez sur l'icône de la carte en bas à droite, positionnez le point en cliquant à un endroit de la carte et validez."
                ></TextDetails>
                <TextDetails
                  title="Conseils"
                  description="Nous vous conseillons de mettre votre téléphone en paysage. N'hésitez pas à vous déplacer dans les rues. Zoomez pour regarder les panneaux, les noms de rue, les bus ou voitures. N'hésitez pas à valider même si vous ne savez pas pour découvrir la destination suivante."
                ></TextDetails>
                <p className="paragraph section-paragraph paragraph-center">
                  Nous conseillons de mettre le téléphone en{" "}
                  <span className="italic"> mode paysage</span>.{" "}
                  <img
                    src={mobileLandscapeIcon}
                    alt="Phone to landscape icon"
                    className={cls.landscape_icon}
                  ></img>
                </p>
                <p className="paragraph section-paragraph paragraph-center">
                  Nous espérons que ce jeu vous permettra de vous amuser !
                </p>
              </>
            )}
          </section>
          <section className="section">
            <h2 className="title section-title title-center">
              Cliquez sur un thème
            </h2>
            <EnterGame className={cls.enter_game} />
          </section>
          {/* <section className="section">
            <h2 className="title section-title title-center">Tutorial</h2>
            <p className="paragraph section-paragraph paragraph-center">
              Déplacez-vous dans la vue proposée par StreetView. Vous pouvez
              avancer/reculer et zoomer/dézoomer pour essayer de trouver des
              indications par rapport à votre position.
            </p>
            <p className="paragraph section-paragraph paragraph-center">
              En bas à droite, vous trouverez une carte Google Maps. Vous pouvez
              l'agrandir et zoomer dessus. L'objectif va être de{" "}
              <strong>cliquez-dessus</strong> à l'endroit le plus précis
              selon-vous par rapport à la position.
            </p>
            <p className="paragraph section-paragraph paragraph-center">
              Une fois cela fait, cliquez sur <strong>"Valider"</strong> et
              regardez le résultat !
            </p>
          </section> */}
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
