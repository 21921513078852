import { useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "../UI/Dropdown/Dropdown";
import Button from "../UI/Button/Button";
import Checkbox from "../UI/Checkbox/Checkbox";

import spbw from "../../utils/spbw";

import gameConfig from "../../config/game.json";
import cls from "./enter-game.module.css";
import { redirect } from "@remix-run/router";
import { useNavigate } from "react-router";

// Images
import homeIcon from "../../assets/img/home-icons/houses-icn.png";
import calendarIcon from "../../assets/img/home-icons/cal-icn.png";
import travelIcon from "../../assets/img/home-icons/travel-icn.png";
import dreyTravelIcon from "../../assets/img/home-icons/audrey-travel-icn.png";
import geofTravelIcn from "../../assets/img/home-icons/geof-travel-icn.png";

function EnterGame({ className }) {
  const [pressTimeout, setPressTimeout] = useState(-1);
  const [prefOpened, setPrefOpened] = useState(false);
  const [expOpened, setExpOpened] = useState(false);
  const navigate = useNavigate();

  const themes = [
    {
      themeName: "Nos habitations",
      jsonFile: "habitations.json",
      icon: homeIcon,
    },
    {
      themeName: "Nos évènements marquants",
      jsonFile: "events.json",
      icon: calendarIcon,
    },
    {
      themeName: "Nos voyages",
      jsonFile: "travels.json",
      icon: travelIcon,
    },
    {
      themeName: "Les voyages d'Audrey",
      jsonFile: "drey-travels.json",
      icon: dreyTravelIcon,
    },
    {
      themeName: "Les voyages de Geoffrey",
      jsonFile: "geof-travels.json",
      icon: geofTravelIcn,
    },
  ];

  const prefBtnDown = () => {
    setPressTimeout(
      setTimeout(() => {
        if (expOpened) return;
        setExpOpened(true);
        setPressTimeout(-1);
      }, 2000)
    );
  };
  const prefBtnClick = (evt) => {
    clearTimeout(pressTimeout);
    if (pressTimeout + 1) {
      if (prefOpened) {
        setPrefOpened(false);
        setExpOpened(false);
      } else setPrefOpened(true);
    }
    evt.preventDefault();
  };

  const handleThemeSelect = async (theme) => {
    try {
      const data = await import(`../../assets/data/${theme.jsonFile}`);
      sessionStorage.setItem("explanationViewed", true);
      sessionStorage.setItem("selectedTheme", JSON.stringify(data));
      sessionStorage.setItem("level", 0);
      navigate("/game", { state: { themeData: data } });
    } catch (error) {
      console.error("Error loading theme data:", error);
    }
  };

  return (
    <div className={spbw(cls.enter_game, className)}>
      <div className={cls.theme_selection}>
        {/* Choix de la mission
        - Ajouter des icones pour chacune
        - Ajouter le nombre max par theme ? */}
        {themes.map((theme) => (
          <div
            className={cls.theme_selection_child}
            key={theme.themeName}
            onClick={() => handleThemeSelect(theme)}
          >
            <img
              className={cls.theme_selection_child_img}
              src={theme.icon}
            ></img>
            <h2>{theme.themeName}</h2>
          </div>
        ))}
      </div>
      {/* <form action="/game" method="get">
        <Dropdown
          className={cls.form_item}
          optionList={Object.entries(gameConfig.regionNames)}
          name="region"
        />
        <fieldset
          hidden={!expOpened}
          className={spbw("fieldset", cls.form_item)}
        >
          <legend className="fieldset-legend">Experiments</legend>
          Empty :(
        </fieldset>
        <fieldset
          hidden={!prefOpened}
          className={spbw("fieldset", cls.form_item)}
        >
          <legend className="fieldset-legend">Preferences</legend>
          <label className="fieldset-item">
            <Checkbox name="compass" className="checkbox checkbox-mr" />
            Compass
          </label>
          <label className="fieldset-item">
            <Checkbox
              name="timer"
              defaultChecked={true}
              className="checkbox checkbox-mr"
            />
            Timer
          </label>
          <label className="fieldset-item">
            <Checkbox
              name="mission"
              defaultChecked={true}
              className="checkbox checkbox-mr"
            />
            Mission 1
          </label>
        </fieldset>
        <div className={cls.form_item}>
          <Button
            className="block"
            onPointerDown={prefBtnDown}
            onClick={prefBtnClick}
            onContextMenu={() => false}
          >
            Preferences...
          </Button>
        </div>
        <div className={cls.form_item}>
          <Button type="submit" className="special block">
            Start
          </Button>
        </div> 
      </form>*/}
    </div>
  );
}

EnterGame.propTypes = {
  className: PropTypes.string,
};
EnterGame.defaultProps = {
  className: "",
};

export default EnterGame;
