import PropTypes from "prop-types";

import spbw from "../../utils/spbw";
import strCut from "../../utils/str-cut";
import geoUrl from "../../utils/geo-url";
import readableDistance from "../../utils/readable/readable-distance";
import readableTime from "../../utils/readable/readable-time";
import readablePercentage from "../../utils/readable/readable-percentage";

import calcGeoDistance from "../../utils/calc/calc-geo-distance";
import calcAccuracy from "../../utils/calc/calc-accuracy";
import calcPoints from "../../utils/calc/calc-points";

import gameConfig from "../../config/game.json";
import cls from "./game-results.module.css";
import Button from "../UI/Button/Button";
import { useEffect, useState } from "react";

function GameResults({ className, map, data }) {
  const cutCoords = (a) => a.map((el) => strCut(el.toString(), 7)).join(",");
  const dst = calcGeoDistance(data.guessPos, data.realPos);
  const acc = calcAccuracy(dst);
  const [nextGame, setNextGame] = useState(false);

  useEffect(() => {
    if (data.locationCount + 1 < data.maxThemes) {
      setNextGame(true);
    }
  }, []);

  function resetSessionStorage() {
    sessionStorage.setItem("level", 0);
    sessionStorage.setItem("selectedTheme", null);
  }

  return (
    <div className={spbw(className, cls.results)}>
      <div>
        <div className="container">
          <h2 className="title title-center section-title">Résultats</h2>
          <div className={cls.map}>{map}</div>
          <div className={cls.main}>
            <div className={cls.row}>
              <h2 className={cls.destination_name}>{data.destinationName}</h2>
              <h3 className={cls.destination_date}>{data.date}</h3>
              <p className={cls.col}>{data.destinationDescription}</p>
            </div>
            <div className={"results-info"}>
              <p className={cls.col}>
                Vous étiez à :{" "}
                <span className="italic">{readableDistance(dst)}</span> de la
                destination à trouver.
              </p>
            </div>
            <div className={"results-info"}>
              <p className={cls.col}>
                Temps pour trouver:{" "}
                <span className="italic">{readableTime(data.time)}</span>
              </p>
            </div>
            <div className={"results-info"}>
              <p className={cls.col}>
                Précision:{" "}
                <span className="italic">{readablePercentage(acc)}</span>
              </p>
            </div>
          </div>
          {nextGame ? (
            <Button
              className={"block btn-alt"}
              onClick={() => {
                data.setLocationCount(Number((data.locationCount += 1)));
                sessionStorage.setItem("level", Number(data.locationCount));
                data.setGameEnd(false);
              }}
            >
              Destination suivante
            </Button>
          ) : (
            <>
              <p>
                Vous êtes arrivés à la fin de ce thème. N'hésitez pas à
                <strong> sélectionner un autre thème</strong> en revenant sur
                l'accueil pour découvrir d'autres destinations !
              </p>
              <br />
              <p className={cls.home_link} onClick={resetSessionStorage}>
                <a href="/">Accueil</a>
              </p>
              <br />
              <p>
                Envie de comparer vos scores ? Il suffit de cliquer juste en
                dessous !
              </p>
              <a href="/stats">Scores</a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

GameResults.propTypes = {
  className: PropTypes.string,
  map: PropTypes.object,
  data: PropTypes.object,
};
GameResults.defaultProps = {
  className: "",
  map: <span />,
  data: {},
};

export default GameResults;
