import PropTypes from "prop-types";

import SummaryRow from "../SummaryRow/SummaryRow";

import readableTime from "../../utils/readable/readable-time";
import readablePercentage from "../../utils/readable/readable-percentage";

import regionImg from "../../assets/img/region.png";
import timerImg from "../../assets/img/timer.png";
import pointsImg from "../../assets/img/points.png";
import accuracyImg from "../../assets/img/accuracy.png";

import cls from "./summary-section.module.css";

function SummarySection({ history }) {
  const smTotal = {
    locations: 0,
    time: 0,
    points: 0,
    accuracy: 0, // To calculate average
  };
  const smBest = {
    accuracy: 0,
    time: Infinity,
    points: 0,
  };
  history.forEach((el) => {
    smTotal.locations++;
    smTotal.time += el.tm;
    smTotal.points += el.pt;
    smTotal.accuracy += el.ac;

    smBest.accuracy = Math.max(smBest.accuracy, el.ac);
    smBest.time = Math.min(smBest.time, el.tm);
    smBest.points = Math.max(smBest.points, el.pt);
  });
  const smAverage = history.length
    ? {
        accuracy: smTotal.accuracy / history.length,
        time: ~~(smTotal.time / history.length),
        points: ~~(smTotal.points / history.length),
      }
    : {
        accuracy: 0,
        time: Infinity,
        points: 0,
      };

  return (
    <div>
      <div>
        <h3 className="title title-s title-center section-title">Total</h3>
        <SummaryRow
          className={cls.row}
          cards={[
            {
              title: "Destinations découvertes",
              value: String(smTotal.locations),
              icon: regionImg,
            },
            {
              title: "Temps de jeu",
              value: readableTime(smTotal.time),
              icon: timerImg,
            },
            {
              title: "Points",
              value: String(smTotal.points),
              icon: pointsImg,
            },
          ]}
        />
      </div>
      <div>
        <h3 className="title title-s title-center section-title">Meilleurs</h3>
        <SummaryRow
          className={cls.row}
          cards={[
            {
              title: "Précision",
              value: readablePercentage(smBest.accuracy),
              icon: accuracyImg,
            },
            {
              title: "Temps pour deviner",
              value: readableTime(smBest.time),
              icon: timerImg,
            },
            {
              title: "Points par localisation",
              value: String(smBest.points),
              icon: pointsImg,
            },
          ]}
        />
      </div>
      <div>
        <h3 className="title title-s title-center section-title">Moyenne</h3>
        <SummaryRow
          className={cls.row}
          cards={[
            {
              title: "Précision",
              value: readablePercentage(smAverage.accuracy),
              icon: accuracyImg,
            },
            {
              title: "Temps pour deviner",
              value: readableTime(smAverage.time),
              icon: timerImg,
            },
            {
              title: "Points par localisation",
              value: String(smAverage.points),
              icon: pointsImg,
            },
          ]}
        />
      </div>
    </div>
  );
}

SummarySection.propTypes = {
  history: PropTypes.array,
};
SummarySection.defaultProps = {
  history: [],
};

export default SummarySection;
