import spbw from "../../utils/spbw";

import origLogo from "../../assets/img/original.svg";
import cls from "./footer.module.css";

function Footer() {
  return <footer className={cls.footer}></footer>;
}

export default Footer;
