import React, { useState } from "react";
import cls from "./text-details.module.css";

const TextDetails = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        onClick={toggleOpen}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
          margin: "10px 0px",
        }}
      >
        <span style={{ marginRight: "8px" }}>{isOpen ? "▼" : "▶"}</span>
        <h3 className={cls.title}>{title}</h3>
      </div>
      {isOpen && <p style={{ marginTop: "10px" }}>{description}</p>}
    </div>
  );
};

export default TextDetails;
